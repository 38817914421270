import { LoggingServicePort } from '../../../shared/ports'

export class PerformanceTrackingService {
  private start: DOMHighResTimeStamp | null = null
  private readonly metricNameCbLoadingTime = 'rum_consent_banner_loading_time'
  private readonly metricNameCbInit = 'consent_banner_init'
  private readonly metricNameCbLoaded = 'consent_banner_loaded'
  private readonly invalidMetricValue = -1

  constructor(
    private loggingService: LoggingServicePort,
    private logFraction: number = 0.001
  ) {}

  init(): void {
    if (Math.random() <= this.logFraction) {
      this.start = performance.now()
    }
    this.trackPerformanceMetric(this.metricNameCbInit)
  }

  trackOTBannerLoadTime(): void {
    if (this.start) {
      this.loggingService.logMessage(
        'Consent banner loaded in ' +
          Math.round(performance.now() - this.start) +
          ' ms'
      )
    }
    this.calculateCbLoadingTimeAndSendItToBeaconService()
  }

  trackPerformanceMetric(performanceMetric: string): void {
    if (this.isPerformanceAvailable()) {
      window.performance.mark(performanceMetric)
    }
  }

  calculatePerformanceTime(startMetric: string, endMetric: string): number {
    try {
      if (this.isPerformanceAvailable()) {
        const startEntries = window.performance.getEntriesByName(startMetric)
        const endEntries = window.performance.getEntriesByName(endMetric)

        if (startEntries.length > 0 && endEntries.length > 0) {
          const start = startEntries[0].startTime
          const end = endEntries[0].startTime

          if (typeof start === 'number' && typeof end === 'number') {
            return Math.round(end - start)
          }
        }
      }
      return this.invalidMetricValue
    } catch (error) {
      this.loggingService.logError(
        new Error(`Error calculating performance time:`, { cause: error })
      )
      return this.invalidMetricValue
    }
  }

  sendPerformanceMetric(
    performanceMetricName: string,
    performanceMetricValue: number
  ): void {
    if (performanceMetricValue === this.invalidMetricValue) {
      this.loggingService.logError(
        new Error(
          'Unable to send performance metric: invalid performance metric value'
        )
      )
      return
    }
    try {
      if (this.isPerformanceAvailable() && this.isOScaleUserTimingAvailable()) {
        window.performance.mark(performanceMetricName)
        /*                                                         */
        /*               */
        window.o_scale.userTiming.sendBeacon({
          [performanceMetricName]: performanceMetricValue,
        })
      }
    } catch (error) {
      this.loggingService.logError(
        new Error(`Error sending performance metric:`, { cause: error })
      )
    }
  }

  private calculateCbLoadingTimeAndSendItToBeaconService() {
    this.trackPerformanceMetric(this.metricNameCbLoaded)
    const calculatedPerformanceTime = this.calculatePerformanceTime(
      this.metricNameCbInit,
      this.metricNameCbLoaded
    )
    this.sendPerformanceMetric(
      this.metricNameCbLoadingTime,
      calculatedPerformanceTime
    )
  }

  private isPerformanceAvailable(): boolean {
    return !!(window.performance && window.performance.mark)
  }

  private isOScaleUserTimingAvailable(): boolean {
    return !!(
      window.o_scale &&
      window.o_scale.userTiming &&
      window.o_scale.userTiming.sendBeacon
    )
  }
}
